/* Top Spacing */
.section .spacing-none {
    height: 0;
}

.section .spacing-xs, 
.section .spacing-xsmall {
    height: 1rem;
}

.section .spacing-sm, 
.section .spacing-small {
    height: 2rem;
}

.section .spacing-md, 
.section .spacing-medium {
    height: 3rem;
}

.section .spacing-lg, 
.section .spacing-large {
    height: 4rem;
}

.section .spacing-xl, 
.section .spacing-xlarge {
    height: 5.5rem;
}


/* Top Divider */
.section .top-1 {
    background-image: linear-gradient(var(--bg-color-1), rgba(255, 0, 0, 0));
}

.section .top-2 {
    background-image: linear-gradient(var(--bg-color-2), rgba(255, 0, 0, 0));
}

.section .top-3 {
    background-image: linear-gradient(var(--bg-color-3), rgba(255, 0, 0, 0));
}

.section .top-4 {
    background-image: linear-gradient(var(--bg-color-4), rgba(255, 0, 0, 0));
}

.section .top-light {
    background-image: linear-gradient(var(--bg-color-light), rgba(255, 0, 0, 0));
}

.section .top-dark {
    background-image: linear-gradient(var(--bg-color-dark), rgba(255, 0, 0, 0));
}

.section .top-default {
    background-image: linear-gradient(var(--bg-color-default), rgba(255, 0, 0, 0));
}

.section .top-zegoda {
    background-image: linear-gradient(var(--bg-color-zegoda), rgba(255, 0, 0, 0));
}


/*  */
.section .content {
    margin-top: 30px;
}