.navbar {
    display: flex;
    align-items: center;
}

.navbar .branding {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.navbar .name {
    font-size: 1.85rem;
}

.navbar .nav-logo {
    width: 50px;
    height: 50px;
}

.navbar .title {
    flex-grow: 2;
    text-align: center;
    font: 3rem 'Roboto', sans-serif;
    font-weight: bold;
    
}

.navbar .menuitem i {
    margin-right: 5px;
    color: #204182;
}