.team-section .nophoto-frame {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
}

.team-section .nophoto {
    width: 100%;
    height: 395px;
}

.team-section .title {
    margin-top: 0.5rem;
}

.team-section .bio {
    font-size: 0.9rem;
}

.team-section .buttons {
    display: flex;
    justify-content: center;
}

.team-section button {
    background-color: var(--bg-color-6-50);
}

.team-section {
    display: flex;
    justify-content: center;
}

.team-section .team-member-highlight {
    max-width: 750px;
    width: 100%;
}

@media screen and (min-width: 601px) {
    .team-section .heading {
        flex-direction: column;
    }
}

.team-section .heading {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.team-section .photo {
    width: 100%;
    max-width: 200px;
}

.team-section .details {
    width: 100%;
}

.team-section .team-member {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
