.service-areas-section .service-area {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.service-areas-section .service-area .title {
    font-size: 18px;
    padding: 1rem;
}

.service-areas-section .service-area .description {
    text-align: left;
    padding: 0.5rem;
}
