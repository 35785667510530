:root {
    --bg-color-1: #878EBA;
    --bg-color-2: #F7F8FF;
    --bg-color-3: #F1F1E6;
    --bg-color-4: #FFF7D6;
    --bg-color-5: #D2A517;
    --bg-color-6: #173E82;
    --bg-color-6-50: rgba(23, 62, 130, 0.5);
    --light: #f8f9fa;
    --dark: #343a40;
    --white: #fff;
    --zegoda-blue: #204182;
}

body,
html {
    height: 100%;
    line-height: 1.8;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Raleway', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    height: 100%;
}


/* Background Color */
.bg-1 {
    background-color: var(--bg-color-1);
}

.bg-2 {
    background-color: var(--bg-color-2);
}

.bg-3 {
    background-color: var(--bg-color-3);
}

.bg-4 {
    background-color: var(--bg-color-4);
}

.bg-5 {
    background-color: var(--bg-color-5);
}

.bg-6 {
    background-color: var(--bg-color-6);
}

.bg-light {
    background-color: var(--light);
}

.bg-dark {
    background-color: var(--dark);
}

.bg-default {
    background-color: var(--white);
}

.bg-zegoda {
    background-color: var(--zegoda-blue);
    color: var(--white);
}
